// ==============================|| THEME CONFIG  ||============================== //

import { projectId } from './firebase';

const config = {
  defaultPath: '/',
  fontFamily: `'Public Sans', sans-serif`,
  i18n: 'en',
  miniDrawer: false,
  container: true,
  mode: 'light',
  presetColor: 'default',
  themeDirection: 'ltr'
};

export const apiUrl =
  projectId === 'tchop-me-dev'
    ? 'https://tchopme-v7fem6pxoa-uc.a.run.app/api/v1'
    : projectId === 'fair-ceiling-417318'
    ? 'https://tchopme-m5yhezhtga-uc.a.run.app/api/v1'
    : 'https://nonsense_url';

export default config;
export const drawerWidth = 260;
export const drawerClosedWidth = 90;
export const paperBoxShadow = 'rgba(145, 158, 171, 0.16) 0px 4px 8px';
