import { useEffect } from 'react';
import {
  onSnapshot,
  query,
  collection,
  where // getDocs
} from 'firebase/firestore';
import { firestore } from '../firebase';
import { COLLECTIONS_NAMES, ORDERS_STATUS } from '../utils/constant';
import { listTags } from '../store/tagSlice';
import { listMenus } from '../store/menuSlice';
import { setHistoryList, setOrderList } from '../store/orders';
import { fromFirebaseToJs } from '../utils/dateutils';
import { formatLocation, tabValue } from '../utils/stringUtils';
import { initPayment, updateRestaurant } from '../store/auth';
import { changeTheme } from '../store/ui';

export default async function useFirestoreListener({
  dispatch,
  restaurant
}: any) {
  useEffect(() => {
    let categorySubscriber = null as any;
    let tagSubscriber = null as any;
    let menuSubscriber = null as any;
    let orderSubscriber = null as any;
    let orderHistorySubscriber = null as any;
    let sideSubscriber = null as any;
    let restaurantSubscriber = null as any;
    let paymentMethodSubscriber = null as any;

    const { TAG, DISHES_BEVERAGE, ORDERS, SIDES, RESTAURANTS, paymentMethods } =
      COLLECTIONS_NAMES;
    tagSubscriber = onSnapshot(
      query(collection(firestore, TAG)),
      (querySnapshot) => {
        const items = [] as any;
        querySnapshot?.forEach((doc) => {
          const data = doc.data();
          items.push({
            id: doc.id,
            ...data
          });
        });
        dispatch(listTags(items));
      }
    );
    menuSubscriber = onSnapshot(
      query(
        collection(firestore, DISHES_BEVERAGE),
        where('groupID', '==', restaurant?.id ?? '')
        //where('isDeleted', '==', false)
      ),

      (querySnapshot) => {
        const items = [] as any;
        querySnapshot?.forEach((doc) => {
          const data = doc.data();

          items.push({
            id: doc.id,
            ...data,
            // endDate: fromFirebaseToJs(data?.endDate),
            updatedAt: fromFirebaseToJs(data.updatedAt),
            createdAt: fromFirebaseToJs(data.createdAt)
          });
        });
        dispatch(listMenus(items));
      }
    );

    sideSubscriber = onSnapshot(
      query(
        collection(firestore, SIDES),
        where('groupID', '==', restaurant?.id ?? '')
        //where('isDeleted', '==', false)
      ),

      (querySnapshot) => {
        const items = [] as any;
        querySnapshot?.forEach((doc) => {
          const data = doc.data();

          items.push({
            id: doc.id,
            ...data,
            updatedAt: fromFirebaseToJs(data.updatedAt),
            createdAt: fromFirebaseToJs(data.createdAt)
          });
        });
        dispatch(listMenus(items));
      }
    );

    orderSubscriber = onSnapshot(
      query(
        collection(firestore, ORDERS),
        where('restaurantID', '==', restaurant?.id ?? ''),
        where('cancelled', '==', false),
        where('status', '!=', ORDERS_STATUS.DELIVERED)
      ),
      (querySnapshot) => {
        const items = [] as any;
        querySnapshot?.forEach((doc) => {
          const data = doc.data();
          items.push({
            ...data,
            id: doc.id,
            createdAt: fromFirebaseToJs(data?.createdAt),
            tab: tabValue(data?.status),
            deliveryAddress: {
              ...data?.deliveryAddress,
              street: data?.deliveryAddress?.street
            }
          });
        });
        dispatch(setOrderList(items));
      }
    );

    orderHistorySubscriber = onSnapshot(
      query(
        collection(firestore, ORDERS),
        where('restaurantID', '==', restaurant?.id ?? ''),
        where('cancelled', '==', false),
        where('status', '==', ORDERS_STATUS.DELIVERED)
      ),

      (snapshot) => {
        snapshot.forEach((doc) => {
          const items: any = [];
          const data = doc.data();
          items.push({
            ...data,
            createdAt: fromFirebaseToJs(data?.createdAt),
            updateAt: fromFirebaseToJs(data?.updateAt),
            deliveryAddress: {
              ...data?.deliveryAddress,
              street: data?.deliveryAddress?.street
            }
          });

          dispatch(setHistoryList(items));
        });
      }
    );

    restaurantSubscriber = onSnapshot(
      query(
        collection(firestore, RESTAURANTS),
        where('id', '==', restaurant?.id ?? '')
      ),

      (querySnapshot) => {
        const items = [] as any;
        querySnapshot?.forEach((doc) => {
          const data = doc.data();

          items.push({
            id: doc.id,
            ...data,
            updatedAt: fromFirebaseToJs(data.updatedAt),
            createdAt: fromFirebaseToJs(data.createdAt)
          });
        });
        const theme = {
          primary: { main: items[0]?.theme?.primary },
          secondary: { custom: items[0]?.theme?.secondary },
          accent: items[0]?.theme?.accent ?? '#D77910'
        };
        dispatch(changeTheme(theme));
        dispatch(updateRestaurant(items[0]));
      }
    );

    if (restaurant?.id) {
      paymentMethodSubscriber = onSnapshot(
        query(
          collection(
            firestore,
            `${RESTAURANTS}/${restaurant?.id ?? ''}/${paymentMethods}`
          )
          // where('id', '==', restaurant?.id ?? '',)
        ),

        (querySnapshot) => {
          const items = [] as any;
          querySnapshot?.forEach((doc) => {
            const data = doc.data();

            items.push({
              id: doc.id,
              ...data
            });
          });
          dispatch(initPayment(items));
        }
      );
    }

    return () => {
      categorySubscriber?.();
      tagSubscriber?.();
      menuSubscriber?.();
      orderSubscriber?.();
      orderHistorySubscriber?.();
      sideSubscriber?.();
      restaurantSubscriber?.();
      paymentMethodSubscriber?.();
    };
  }, [dispatch, restaurant?.id]);
}
